.final-form {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 2.4rem;
    .image_space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        height: 4rem;
        border-radius: 0.8rem 0 0 0.8rem;
        border: 2px solid yellow;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;
        border-radius: .8rem;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    }
    input {
        height: 4rem;
        width: 100%;
        border-radius: 0 0.8rem 0.8rem 0;
        border: 2px solid yellow;
        padding: 0 0 0 1.6rem;
    }
    h3 {
        margin-bottom: 2.4rem;
    }
    .img {
        height: 2.4rem;
        width: 2.4rem;
        fill: $yellow;
    }
}

.submit-button {
    display: block;
    margin: 1.6rem auto 2.4rem auto !important;
    background-color: #ffed4a !important;
    color: black !important;
}

.final-form-buy {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 2.4rem;
    .image_space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        height: 4rem;
        border-radius: 0.8rem 0 0 0.8rem;
        border: 2px solid #007fff;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;
        border-radius: .8rem;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    }
    input {
        height: 4rem;
        width: 100%;
        border-radius: 0 0.8rem 0.8rem 0;
        border: 2px solid #007fff;
        padding: 0 0 0 1.6rem;
    }
    h3 {
        margin-bottom: 2.4rem;
    }
    .img {
        height: 2.4rem;
        width: 2.4rem;
        fill: #007fff;
    }
}

.submit-button_sell {
    display: block;
    margin: 1.6rem auto 2.4rem auto !important;
    background-color: #007fff !important;
    color: black !important;
}

.final-form-invest {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 2.4rem;
    .image_space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        height: 4rem;
        border-radius: 0.8rem 0 0 0.8rem;
        border: 2px solid $green;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;
        border-radius: .8rem;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    }
    input {
        height: 4rem;
        width: 100%;
        border-radius: 0 0.8rem 0.8rem 0;
        border: 2px solid $green;
        padding: 0 0 0 1.6rem;
    }
    h3 {
        margin-bottom: 2.4rem;
    }
    .img {
        height: 2.4rem;
        width: 2.4rem;
        fill: $green;
    }
}

.submit-button_sell {
    display: block;
    margin: 1.6rem auto 2.4rem auto !important;
    background-color: $green !important;
    color: black !important;
}

.final-form-sell {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 2.4rem;
    .image_space {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        height: 4rem;
        border-radius: 0.8rem 0 0 0.8rem;
        border: 2px solid $red;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;
        border-radius: .8rem;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    }
    input {
        height: 4rem;
        width: 100%;
        border-radius: 0 0.8rem 0.8rem 0;
        border: 2px solid $red;
        padding: 0 0 0 1.6rem;
    }
    h3 {
        margin-bottom: 2.4rem;
    }
    .img {
        height: 2.4rem;
        width: 2.4rem;
        fill: $red;
    }
}

.submit-button_sell {
    display: block;
    margin: 1.6rem auto 2.4rem auto !important;
    background-color: #007fff !important;
    color: black !important;
}