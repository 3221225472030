.header {
  height: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    height: 7rem;
    padding: 0.8rem 2.4rem;
  }

  a {
    margin: 0 1.6rem;
    font-size: 1.44rem;
    color: $black;
  }

  nav {
    padding-right: 1.6rem;
  }

  @include media("<=tablet") {
    flex-direction: column;
    height: auto;
    padding-bottom: 1.6rem;
    
    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    a {
        margin: .4rem auto;
    }
  }
}
