// Animation Page
.animation__question__wrapper {
  margin: 0 auto;
  justify-self: center;
  width: 100vw;
  height: 100vh;
  animation: skyChange 60s linear infinite;
  background: skyblue;
}

.bottom_grass {
  background-color: green;
  height: 40px;
  width: 100vw;
  bottom: 0;
  position: absolute;
}

.window_size_small {
  background-image: url("../../../assets/hawktree.png");
  height: 100vh;
  // set size & stop image repetition
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.animation_error {
  position: fixed;
  font-size: 20px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cloud_top {
  background: rgba(255, 255, 255);
  width: 300px;
  height: 100px;
  border-radius: 150px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  color: black;
  z-index: 1;
}

.cloud {
  background: rgba(255, 255, 255);
  width: 300px;
  height: 100px;
  border-radius: 150px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  animation: move 3s infinite;
  color: black;
}

.cloud_font {
  z-index: -1;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  align-content: center;
  p {
    color: red;
    font-weight: bold;
  }
}

.cloud:after {
  content: "";
  background: url("../../../assets/resize.png");
  background: rgba(255, 255, 255);
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -50px;
  left: 50px;
  z-index: -100;
}

.cloud:before {
  content: "";
  background: rgba(255, 255, 255);
  position: absolute;
  width: 170px;
  height: 150px;
  border-radius: 50%;
  top: -90px;
  right: 40px;
  z-index: -100;
}

@keyframes move {
  0% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(-40px);
  }
}

@keyframes move2 {
  0% {
    transform: translatex(-40px);
  }
  50% {
    transform: translatex(0px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  40% {
    transform: scale(1) rotate(5deg);
  }
  80% {
    transform: scale(1) rotate(-5deg);
  }
  100% {
    transform: scale(0.9) rotate(0);
  }
}

.video_wrapper {
  opacity: 1;
  transition: all 0.5s ease;
}

.video_wrapper.fade-out {
  opacity: 0;
}

.animation_video {
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0 auto;

  // @media (max-width: 1280px) {
  //     width: 1280px;
  // }
  // @media (min-width: 1440px) {
  //     width: 1440px;
  //     height: 100vh;
  // }
  // @media (min-width: 1680px) {
  //     width: 1680px;
  //     height: 100vh;
  // }
  // @media (min-width: 1920px) {
  //     width: 100vw;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     position: absolute;
  //     margin: 0 auto;
  //     background-color: red;
  // }
  // @media (min-width: 1921px) {
  //     width: 100vw;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     position: absolute;
  //     margin: 0 auto;
  //     background-color: red;
  // }
}

.video {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  // @media (max-width: 1280px) {
  //     width: 1280px;
  // }
  // @media (min-width: 1440px) {
  //     width: 1440px;
  //     height: 100vh;
  //     margin: 0 auto;
  // }
  // @media (min-width: 1680px) {
  //     width: 1680px;
  //     height: 100vh;
  // }
  // @media (min-width: 1920px) {
  //     width: 100%;
  //     height: 100vh;
  //     margin: 0 auto;
  // }
}

.video_wrapper {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  // @media (max-width: 1280px) {
  //     width: 1280px;
  // }
  // @media (min-width: 1440px) {
  //     width: 1440px;
  //     height: 100vh;
  //     margin: 0 auto;
  // }
  // @media (min-width: 1680px) {
  //     width: 1680px;
  //     height: 100vh;
  // }
  // @media (min-width: 1920px) {
  //     width: 1920px;
  //     height: 100vh;
  //     margin: 0 auto;
  // }
  // @media (min-width: 1921) {
  //     width: 100vw;
  //     height: 100vh;
  //     margin: 0 auto;
  // }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.6, 0.6);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(2, 2);
  }
}

.background_overlay {
  background-image: url("https://househawkbucket.s3.amazonaws.com/househawkbackgroundborder_1699e3e8fb.png");
  background: none;
  background-repeat: no-repeat;
  // @media (max-width: 3440px) {
  //     margin: 0 auto;
  //     width: 1920px;
  //     height: 100vh;
  //     background-size: auto;
  //     background-repeat: no-repeat;
  // }
}

.background_overlay_rent {
  background-image: url("https://househawkbucket.s3.amazonaws.com/ultra_background_1a7e0fab10.png");
  background: none;
  background-repeat: no-repeat;
  // @media (max-width: 3440px) {
  //     margin: 0 auto;
  //     width: 1920px;
  //     height: 100vh;
  //     background-size: auto;
  //     background-repeat: no-repeat;
  // }
}

.background_overlay_hidden {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: none;
}

.animation_question_box {
  margin: 0 auto;
  justify-self: center;
  width: 300px;
  height: 300px;
}

.animation_question_background_wrapper {
  height: 1920px;
  width: 1080px;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &.buyroute {
    background-image: url("https://househawkbucket.s3.amazonaws.com/househawkonebackground_44f5106498.png");
  }
  &.sellroute {
    // background-image: url("https://househawkbucket.s3.amazonaws.com/Bird_Sell_Static_0e0abb573a.png");
  }
  &.investroute {
    // background-image: url("https://househawkbucket.s3.amazonaws.com/househawkonegreen_bb68cef909.png");
  }
  @media screen {
  }
}

.animation_question_content {
  margin: 0 auto;
  .map_size {
    padding: 6rem 1.5rem;
    width: 65rem;
  }
  .Buttons_Row {
    transform: scale(1);
    transition: all 0.3s cubic-bezier(0.49, -0.34, 0.59, 1.3);
    font-size: 13px;
    margin: 10px 4px;
  }
  .Buttons_Col {
    width: 100%;
    flex-direction: column;
  }
  .Buttons_animation {
    width: 54rem;
    height: 18rem;
    flex-wrap: wrap;
    transition: all 0.3s cubic-bezier(0.49, -0.34, 0.59, 1.3);
    @include media("<=tablet") {
      width: 100%;
    }
  }
  .long-col-buttons {
    width: 100%;
    margin: 0.4rem;
  }
  .step11_animation {
    width: 14rem;
  }
  .step11_wrapper {
    flex-wrap: wrap;
  }
  .step13_buttons {
    width: 45%;
  }
  .currencyinput {
    color: #f9398f;
    input {
      margin: 0 0.8rem;
    }
    .Slider__Range__Row {
      color: white;
    }
  }
}

.Buttons_animation {
  width: 48rem;
  height: 18rem;
  flex-wrap: wrap;
  @include media("<=tablet") {
    width: 100%;
  }
}

.Buttons_Col {
  width: 100%;
  flex-direction: column;
}

.icon-circle-0margin {
  border-radius: 50%;
  padding: 0;
  height: 32px;
  width: 32px;
  margin: 0;
}

.bgrd-yellow {
  background: $yellow;
}

.long-col-buttons {
  width: 100%;
  margin: 0.4rem;
}

// sky animation
@keyframes skyChange {
  0% {
    background: deepskyblue;
  }
  25% {
    background: deepskyblue;
  }
  32% {
    background: aliceblue;
  }
  41% {
    background: deepskyblue;
  }
  70% {
    background: deepskyblue;
  }
  100% {
    background: #00d2ff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a7bd5, #00d2ff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3a7bd5, #00d2ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

.hide {
  @include media("<=tablet") {
    display: none;
  }
}

.show_animation {
  position: "fixed";
  bottom: 0;
  right: 0;
  @include media(">=phone", "<=tablet") {
    display: none;
  }
}
