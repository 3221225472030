.container {
    background-color: white;
    min-height: 100vh;
    padding: 2.4rem 8rem 2.4rem 8rem;
}

.margin-left {
    margin-left: 2.4rem;
    @include media("<=tablet") {
        margin-left: 0;
    }
}

.margin-left12 {
    margin-left: 1.2rem;
}

.margin-left48 {
    margin-left: 4.8rem;
}

.margin-right {
    margin-right: 2.4rem;
}

.margin-right12 {
    margin-right: 1.2rem;
}

.margin-right8 {
    margin-right: 0.8rem;
}

.margin-8 {
    margin: 0.8rem !important;
}

.margin-4 {
    margin: 0.4rem !important;
}

.margin-center {
    margin: 0 auto;
}

.margin-bottom {
    margin-bottom: 1.6rem;
}

.margin-topbott {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.margin-top {
    margin-top: 1.2rem;
}

.margin-top24 {
    margin-top: 2.4rem;
}

.margin-right3px {
    margin-right: 3px;
}

.padding-0-5 {
    padding: 0 5px;
    width: 39px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.justify-space {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.wrap {
    flex-wrap: wrap;
}

.no-wrap {
    flex-wrap: nowrap;
}

.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    background: $white;
    color: $black;
}

.text-left {
    text-align: left;
}

.grey-italicized {
    color: $light-grey;
    font-style: italic;
}

.check-space {
    width: 24rem;
}

.width-50 {
    width: 47%;
    margin-right: 0.8rem;
    @include media("<=tablet") {
        width: 75%;
    }
}

.sm-padding {
    padding: 0.8rem;
}

.purple {
    background-color: #8233d4 !important;
    color: white !important;
    font-size: 1.6rem;
    padding: 0;
}

.geosearchbar {
    width: 361px;
    height: 45px;
    border: 1px solid #8233d4;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    display: flex;
}

.button_width_mod {
    width: 20.5rem;
}