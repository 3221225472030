.buy {
  padding: 0;
  border-radius: 0;
  background: none;
  filter: invert(72%) sepia(62%) saturate(1018%) hue-rotate(359deg)
    brightness(105%) contrast(105%);
}

.buy_stp5 {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 50%;
  background: none;
}

.buy_stp10 {
  height: 24px;
  width: 24px;
  padding: 0;
  border-radius: 0;
  background: none;
  filter: invert(76%) sepia(37%) saturate(2813%) hue-rotate(133deg)
    brightness(99%) contrast(107%);
}

.buy_stp11_label {
  width: 7.2rem;
  height: 9rem;
  @include media("<=tablet") {
    width: 11.2rem;
    height: 6rem;
  }
}
