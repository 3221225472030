/* Sun  */

.theSun {
    opacity: 1;
    box-shadow: 0px 0px 15px 3px yellow;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 1vh;
    left: 83%;
    animation: sunpulse 2s alternate infinite;
    border-radius: 50%;
    background-image: radial-gradient(circle, #ffd200 95%, #f7971e);
}

@keyframes sunpulse {
    from {
        box-shadow: 0 0 100px #ff0, 0 0 100px #ff0;
    }
    to {
        box-shadow: 0 0 25px #ff0, 0 0 75px #ff0;
    }
}


/* Sun rays */

.ray_box {
    position: absolute;
    margin: auto;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70px;
    animation: ray_anim 120s linear infinite;
}

.ray {
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient( top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    margin-left: 10px;
    border-radius: 80% 80% 0 0;
    position: absolute;
    opacity: 0.1;
}

.ray1 {
    height: 170px;
    width: 30px;
    transform: rotate(180deg);
    top: -175px;
    left: 15px;
}

.ray2 {
    height: 100px;
    width: 8px;
    transform: rotate(220deg);
    top: -90px;
    left: 75px;
}

.ray3 {
    height: 170px;
    width: 50px;
    transform: rotate(250deg);
    top: -80px;
    left: 100px;
}

.ray4 {
    height: 120px;
    width: 14px;
    transform: rotate(305deg);
    top: 30px;
    left: 100px;
}

.ray5 {
    height: 140px;
    width: 30px;
    transform: rotate(-15deg);
    top: 60px;
    left: 40px;
}

.ray6 {
    height: 90px;
    width: 50px;
    transform: rotate(30deg);
    top: 60px;
    left: -40px;
}

.ray7 {
    height: 180px;
    width: 10px;
    transform: rotate(70deg);
    top: -35px;
    left: -40px;
}

.ray8 {
    height: 120px;
    width: 30px;
    transform: rotate(100deg);
    top: -45px;
    left: -90px;
}

.ray9 {
    height: 80px;
    width: 10px;
    transform: rotate(120deg);
    top: -65px;
    left: -60px;
}

.ray10 {
    height: 190px;
    width: 23px;
    transform: rotate(150deg);
    top: -185px;
    left: -60px;
}

@keyframes ray_anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}