.content p {
  font-size: 16px;
  line-height: 1.5;
}

.content ol {
  list-style-type: decimal;
  margin-bottom: 1em;
  margin-left: 2em;
  font-size: 16px;
}

.content li {
  margin-bottom: 0.5em;
}

.content h1 {
  font-size: 32px;
  font-weight: bold;
}
