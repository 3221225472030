.other_input_a {
  width: 17rem;
  height: 2rem;
  padding: 1rem;
}

.other_input {
  width: 45%;
  height: 2rem;
  padding: 1rem;
}
