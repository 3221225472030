.landing {
  background: #282c34 url("../../../assets/heroBg.jpg") center;
  background-size: cover;
  min-height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  @include media("<=tablet") {
    padding-top: 12rem;

    h1 {
      font-size: 2.8rem;
    }
  }

  .goals {
    margin-bottom: 20px;
    h4 {
      margin-right: 4.4rem;
    }

    .sm-margin {
      margin-right: 1.9rem;
    }
    @include media("<=tablet") {
      flex-wrap: wrap;
    }
  }

  .landing-location {
    height: 2.2rem;
    border-radius: 4px;
    margin-left: 1rem;
    padding: 0.4rem 0.4rem 0.4rem 1.6rem;
    border: 1px solid $light-grey;
  }
  .arrow_div {
    margin-right: 0.8rem;
  }

  .arrow {
    height: 2rem;
    color: red;
    margin-right: 2rem;
    align-self: center;
    animation: ease-in-out 1s ease-in-out infinite;
  }

  @keyframes ease-in-out {
    0% {
      transform: translate3d(0px, 0px, 0px);
    }

    50% {
      transform: translate3d(16px, 0px, 0px);
    }

    100% {
      transform: translate3d(0px, 0px, 0px);
    }
  }
}

.loc_div {
}

.isred_btn {
  width: 8rem;
  height: 2.8rem;
  background: $red !important;
  color: $white !important;
  font-size: 1.6rem;
}

.red_btn {
  &:hover {
    width: 8rem;
    height: 2.8rem;
    background: $red !important;
    color: $white !important;
    font-size: 1.6rem;
    transition: transform 0.25s ease-out;
  }
}

.isgreen_btn {
  width: 8rem;
  height: 2.8rem;
  background: $green !important;
  color: $white !important;
  font-size: 1.6rem;
}

.green_btn {
  &:hover {
    width: 8rem;
    height: 2.8rem;
    background: $green !important;
    color: $white !important;
    font-size: 1.6rem;
  }
}

.isyellow_btn {
  width: 8rem;
  height: 2.8rem;
  background: $yellow !important;
  color: $black !important;
  font-size: 1.6rem;
}

.yellow_btn {
  &:hover {
    width: 8rem;
    height: 2.8rem;
    background: $yellow !important;
    color: $black !important;
    font-size: 1.6rem;
  }
}

.isblue_btn {
  width: 8rem;
  height: 2.8rem;
  background: $blue !important;
  color: $black !important;
  font-size: 1.6rem;
}

.blue_btn {
  &:hover {
    width: 8rem;
    height: 2.8rem;
    background: $blue !important;
    color: $black !important;
    font-size: 1.6rem;
  }
}

.rent_hover {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: none;
  padding-top: 2.4rem;
  margin: 0 0.4rem;
  &.isrent_hover {
    border-top: 3px solid $yellow !important;
    padding-top: 2.1rem;
  }
}

.buy_hover {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: none;
  padding-top: 2.4rem;
  margin: 0 0.4rem;
  &.isbuy_hover {
    border-top: 3px solid $blue !important;
    padding-top: 2.1rem;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
}

.sell_hover {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: none;
  padding-top: 2.4rem;
  margin: 0 0.4rem;
  &.issell_hover {
    border-top: 3px solid $red !important;
    padding-top: 2.1rem;
  }
}

.invest_hover {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: none;
  padding-top: 2.4rem;
  margin: 0 0.4rem;
  &.isinvest_hover {
    border-top: 3px solid $green !important;
    padding-top: 2.1rem;
  }
}
