.rent {
    padding: 0;
    border-radius:0;
    background: none;
    filter: invert(72%) sepia(62%) saturate(1018%) hue-rotate(359deg) brightness(105%) contrast(105%);
}

.rent_stp5 {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 50%;
    background: none;
}

.rent_stp10 {
    height: 24px;
    width: 24px;
    padding: 0;
    border-radius:0;
    background: none;
    filter: invert(72%) sepia(62%) saturate(1018%) hue-rotate(359deg) brightness(105%) contrast(105%);
}